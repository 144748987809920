// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirmed-account-components-content-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/components/Content.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-components-content-tsx" */),
  "component---src-pages-confirmed-account-components-footer-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/components/Footer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-components-footer-tsx" */),
  "component---src-pages-confirmed-account-components-hero-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/components/Hero.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-components-hero-tsx" */),
  "component---src-pages-confirmed-account-components-perk-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/components/Perk.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-components-perk-tsx" */),
  "component---src-pages-confirmed-account-confirmed-account-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/confirmed-account.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-confirmed-account-tsx" */),
  "component---src-pages-confirmed-account-index-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/index.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-index-tsx" */),
  "component---src-pages-confirmed-account-styled-confirmed-account-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/ConfirmedAccountContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-confirmed-account-container-tsx" */),
  "component---src-pages-confirmed-account-styled-content-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/ContentContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-content-container-tsx" */),
  "component---src-pages-confirmed-account-styled-content-image-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/ContentImage.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-content-image-tsx" */),
  "component---src-pages-confirmed-account-styled-content-title-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/ContentTitle.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-content-title-tsx" */),
  "component---src-pages-confirmed-account-styled-copyright-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/Copyright.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-copyright-tsx" */),
  "component---src-pages-confirmed-account-styled-footer-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/FooterContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-footer-container-tsx" */),
  "component---src-pages-confirmed-account-styled-hero-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/HeroContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-hero-container-tsx" */),
  "component---src-pages-confirmed-account-styled-hero-logo-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/HeroLogo.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-hero-logo-tsx" */),
  "component---src-pages-confirmed-account-styled-hero-subtitle-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/HeroSubtitle.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-hero-subtitle-tsx" */),
  "component---src-pages-confirmed-account-styled-hero-title-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/HeroTitle.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-hero-title-tsx" */),
  "component---src-pages-confirmed-account-styled-perk-body-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/PerkBody.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-perk-body-tsx" */),
  "component---src-pages-confirmed-account-styled-perk-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/PerkContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-perk-container-tsx" */),
  "component---src-pages-confirmed-account-styled-perk-image-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/PerkImage.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-perk-image-tsx" */),
  "component---src-pages-confirmed-account-styled-perk-title-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/PerkTitle.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-perk-title-tsx" */),
  "component---src-pages-confirmed-account-styled-social-network-link-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/SocialNetworkLink.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-social-network-link-tsx" */),
  "component---src-pages-confirmed-account-styled-social-networks-container-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/SocialNetworksContainer.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-social-networks-container-tsx" */),
  "component---src-pages-confirmed-account-styled-success-icon-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/confirmed-account/styled/SuccessIcon.tsx" /* webpackChunkName: "component---src-pages-confirmed-account-styled-success-icon-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/landing/src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */)
}

